/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../context/auth-context";
import { useHttpRequest } from "../../../../../hooks/httpRequest-hook";
import { useForm } from "../../../../../hooks/form-hook";
import { MinLength, isRequired, isText, isAlphanumeric, isNumber, isCvec } from "../../../../../utils/validators";
import { ToastContainer, toast } from 'react-toastify';

// Components
import InLineLoadingSpinner from "../../../../LoadingSpinner/InlineLoadingSpinner";
import Input from "../../../../Input/Input";
import ActionBtn from "../../../../Buttons/ActionBtn/ActionBtn";
import FilePicker from "../../../../FilePicker/FilePicker";

// Styles
import styles from "./CivilState.module.scss";

const CivilState = ({ student, setStudent }) => {

    // Authentication context
    const auth = useContext(AuthContext);

    // Http request hook
    const { isLoading, error, okHttp, resetHttpRequest, sendRequest } = useHttpRequest();

    // FormState Hook
    const [formState, inputHandler] = useForm(
        {
            birth_date: {
                value: student.birth_date,
                isValid: student.birth_date ? true : false,
            },
            place_of_birth: {
                value: student.place_of_birth,
                isValid: student.place_of_birth ? true : false,
            },
            cvec: { value: student.cvec ? student.cvec : "", isValid: true },
            file: { value: "", isValid: false },
        },
        false
    );

    const submit = async () => {

        if (student.cvec != formState.inputs.cvec.value && !formState.inputs.file.value) {
            toast.error(`${"Merci de fournir votre attestation de CVEC correspondant à votre numéro d'attestation."}`, {
                position: "top-right",
                autoClose: 4500,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });
            return;
        } else if (formState.inputs.file.value && (student.cvec == formState.inputs.cvec.value || formState.inputs.cvec.value == '')) {
            toast.error(`${"Merci de fournir votre numéro de CVEC correspondant à votre attestation."}`, {
                position: "top-right",
                autoClose: 4500,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "colored",
            });
            return;
        }
        
        const formData = new FormData();

        formData.append("birth_date", formState.inputs.birth_date.value);
        formData.append("place_of_birth", formState.inputs.place_of_birth.value);
        formData.append("cvec", formState.inputs.cvec.value);
        if (formState.inputs.file.value) formData.append("file", formState.inputs.file.value);

        const url = `${process.env.REACT_APP_API_HOST}/profile/profile`;
        try {
            const response = await sendRequest(url, "POST", formData, {
                Authorization: "Bearer " + auth.token,
            });

            setStudent((prevData) => {
                const { birth_date, place_of_birth, cvec } = formData;
                return { ...prevData, birth_date, place_of_birth, cvec };
            });
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (okHttp || error) setTimeout(resetHttpRequest, 4000);
    }, [okHttp, error]);

    return (
        <div className={styles.civilStateInput}>
            {okHttp && !isLoading && (
                <p style={{ color: "#00b61d", textAlign: "center" }}>
                    Votre demande a bien été prise en compte. Merci.
                </p>
            )}
            {error && !isLoading && (
                <p style={{ color: "#e40000", textAlign: "center" }}>
                    Une erreur a été détectée, s'il vous plait, veuillez réessayer ultèrieurement.
                </p>
            )}
            <Input
                id="birth_date"
                name="birth_date"
                type="date"
                typeOfInput="input"
                pattern="\d{4}-\d{2}-\d{2}"
                label="Date de naissance *"
                placeholder="jj/mm/aaaa"
                validators={[isRequired()]}
                errorText="Champ requis."
                initialValue={formState.inputs.birth_date.value}
                initialValid={formState.inputs.birth_date.isValid}
                onInput={inputHandler}
                styles={styles.input}
            />
            <Input
                id="place_of_birth"
                name="place_of_birth"
                type="text"
                typeOfInput="input"
                label="Lieu de naissance *"
                placeholder="Nantes"
                validators={[isRequired()]}
                errorText="Champ requis."
                initialValue={formState.inputs.place_of_birth.value}
                initialValid={formState.inputs.place_of_birth.isValid}
                onInput={inputHandler}
                styles={styles.input}
            />
            <Input
                id="cvec"
                name="cvec"
                type="text"
                typeOfInput="input"
                label="Numéro d'attestation CVEC"
                validators={[MinLength(4), isCvec()]}
                errorText="Format LLLCLLLLLLCC (L = Lettre, C = chiffre) uniquement"
                initialValue={formState.inputs.cvec.value}
                initialValid={formState.inputs.cvec.isValid}
                onInput={inputHandler}
                styles={styles.input}
            />
            <div className={styles.documentsDiv}>
                <p className={styles.cvecP}>Attestation de CVEC</p>
                <div className={styles.divCvec}>
                    {student.attestationCvec && (
                        <FilePicker
                            mimeTypes=".jpg, .jpeg, .pdf"
                            inputHandler={inputHandler}
                            formState={formState}
                            labelProps={"Attestation CVEC transmise."}
                            completed={true}
                        />
                    )}
                    <FilePicker
                        mimeTypes=".jpg, .jpeg, .pdf"
                        inputHandler={inputHandler}
                        formState={formState}
                        labelProps={"Cliquez ici pour nous transmettre ou changer votre attestation de CVEC *"}
                    />
                </div>
            </div>
            <ActionBtn
                id="subitCivilState"
                btnType="contained"
                btnStyle={styles.submitBtn}
                activeBtnStyle={styles.btn_active}
                btnText="ENREGISTRER"
                textStyle={styles.btn_text}
                onClick={submit}
            />
            {isLoading && <InLineLoadingSpinner />}
            <ToastContainer />
        </div>
    );
};

export default CivilState;
