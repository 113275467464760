import React, { useState } from "react";
import { compressFilenameStr } from "../../utils/helperFunctions";

// Icons
import removeIcon from "../../assets/images/times-solid.svg";
import fileIcon from "../../assets/images/file.svg";
import imgIcon from "../../assets/images/file-image.svg";
import pdfIcon from "../../assets/images/file-pdf.svg";
import wordIcon from "../../assets/images/file-word.svg";
import excelIcon from "../../assets/images/file-excel.svg";
import powerPointIcon from "../../assets/images/file-powerpoint.svg";

// Styles
import styles from "./FilePicker.module.scss";

const FilePicker = ({ mimeTypes, inputHandler, formState, disabled, labelProps, completed, propsFileName }) => {

    // MIME Type Icons
    const MIME_TYPE_MAP = {
        "image/jpg": {
            icon: imgIcon,
            color: styles.imgIconColor,
            mimeType: ".jpg",
        },
        "image/jpeg": {
            icon: imgIcon,
            color: styles.imgIconColor,
            mimeType: ".jpeg",
        },
        "image/png": {
            icon: imgIcon,
            color: styles.imgIconColor,
            mimeType: ".png",
        },
        "image/gif": {
            icon: imgIcon,
            color: styles.imgIconColor,
            mimeType: ".gif",
        },
        "application/pdf": {
            icon: pdfIcon,
            color: styles.pdfIconColor,
            mimeType: ".pdf",
        },
        "application/msword": {
            icon: wordIcon,
            color: styles.wordIconColor,
            mimeType: ".doc",
        },
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": {
            icon: wordIcon,
            color: styles.wordIconColor,
            mimeType: ".docx",
        },
        "application/vnd.ms-powerpoint": {
            icon: powerPointIcon,
            color: styles.powerPointIconColor,
            mimeType: ".ppt",
        },
        "application/vnd.openxmlformats-officedocument.presentationml.presentation": {
            icon: powerPointIcon,
            color: styles.powerPointIconColor,
            mimeType: ".pptx",
        },
        "application/vnd.ms-excel": {
            icon: excelIcon,
            color: styles.excelIconColor,
            mimeType: ".xlsx",
        },
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": {
            icon: excelIcon,
            color: styles.excelIconColor,
            mimeType: ".doc",
        },
    };

    const pickFileHandler = (event) => {

        // Extract File Blob
        const file = event.target.files[0];

        // Set File States
        if (file) inputHandler("file", file, true);
        else inputHandler("file", undefined, false);
    };

    let fileName = <p className={disabled ? styles.disabled_filename : styles.filename}>Aucun fichier séléctionné</p>;
    
    let label;
    if (disabled) label = <p className={styles.disabled_label}>Sélection indisponible</p>;
    else if (completed) {
        label = <p className={styles.label} style={{ fontWeight: "bold"}}>{labelProps}</p>;
        fileName = (
            <p className={styles.filename}>{propsFileName}</p>
        );
    }
    else if (!formState.inputs.file.isValid) label = <p className={styles.label}>{labelProps ? labelProps : "Cliquez ici pour ajouter un fichier"}</p>;
    else {      
        label = (
            <button
                className={styles.remove_btn}
                onClick={(event) => {
                    event.preventDefault();
                    inputHandler("file", undefined, false);
                }}
            >
                <div className={styles.remove_wrapper}>
                    <p className={styles.label}>Enlever le fichier</p>
                    <img className={styles.remove_icon} src={removeIcon} alt="" />
                </div>
            </button>
        );
        let filenameStr = compressFilenameStr(
            8,
            formState.inputs.file.value.name,
            MIME_TYPE_MAP[formState.inputs.file.value.type].mimeType
        );
        fileName = (
            <p className={styles.filename}>{filenameStr || formState.inputs.file.value.name}</p>
        );
    }

    if (completed) {
        return (
            <div className={styles.container}>
                <label htmlFor="upload-button" className={styles.wrapper}>
                    <div className={styles.text_wrapper}>
                        {fileName}
                        {label}
                    </div>
                    <div className={styles.icon_wrapper}>
                        {disabled ? (
                            <img className={styles.disabeldIconColor} src={fileIcon} alt="" />
                        ) : (
                            <img
                                className={MIME_TYPE_MAP["application/pdf"].color}
                                src={MIME_TYPE_MAP["application/pdf"].icon}
                                alt=""
                            />
                        )}
                    </div>
                </label>
                <input
                    id="upload-button"
                    name="file"
                    type="file"
                    accept={mimeTypes}
                    style={{ display: "none" }}
                    onChange={pickFileHandler}
                    disabled={disabled}
                />
            </div>
        );
    }


    return (
        <div className={styles.container}>
            <label htmlFor="upload-button" className={styles.wrapper}>
                <div className={styles.text_wrapper}>
                    {fileName}
                    {label}
                </div>
                <div className={styles.icon_wrapper}>
                    {disabled ? (
                        <img className={styles.disabeldIconColor} src={fileIcon} alt="" />
                    ) : (
                        <img
                            className={
                                formState.inputs.file.value
                                    ? MIME_TYPE_MAP[formState.inputs.file.value.type].color
                                    : styles.iconColor
                            }
                            src={
                                formState.inputs.file.value
                                    ? MIME_TYPE_MAP[formState.inputs.file.value.type].icon
                                    : fileIcon
                            }
                            alt=""
                        />
                    )}
                </div>
            </label>
            <input
                id="upload-button"
                name="file"
                type="file"
                accept={mimeTypes}
                style={{ display: "none" }}
                onChange={pickFileHandler}
                disabled={disabled}
            />
        </div>
    );
};

export default FilePicker;
